import axios from 'axios';
import { Poppins, Quicksand } from 'next/font/google';
import React, { useState } from 'react';
import { IoMdClose } from "react-icons/io";
// Importing fonts
const popins = Poppins({ weight: "500", subsets: ['latin'] });
const extrapopins = Poppins({ weight: "600", subsets: ['latin'] });
const quickSand = Quicksand({ weight: "400", subsets: ['latin'] });

export default function GetInstantForm({close,formWidth,closeIcon,heading}) {
  const [loading,setLoading]=useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    course: ""
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }


  // Handle form submission
  const handleSubmit = async(e) => {
    e.preventDefault();
    setLoading(true);
    try {
        const response = await axios.post(`${process.env.url}/api/sendmail`,{
          name: formData.name,
          mobileNumber: formData.mobileNumber,
          email: formData.email,
          course: formData.course,
        })
        console.log("Form Data",response.data)
   
    console.log("Form Data", formData);

    // Reset form
    setFormData({
      name: "",
      email: "",
      mobileNumber: "",
      course: ""
    });
  
  }
  catch (error) {
    console.error('Client-side error:', error); // Log any client-side error
    alert('An error occurred while registering. Please try again.');
  }
  finally{
    setLoading(false);
  }
  }

  return (
    <>
      <section className="container">
        <div className='form-popup'>
        <div className={`form-box`} style={{width:formWidth}}>
       {closeIcon &&(
         <div className='df fjfe'>   
        <IoMdClose size={24} className='cursor' onClick={close}/>
        </div>
        )}
     {heading &&    <h6 className={`fs-20 mfs-16 tac ${extrapopins.className}`}>{heading}</h6> }
          <form className='df fdc fac mt16' onSubmit={handleSubmit}>
            <input
              type='text'
              className={`input-box fs-19 ${popins.className}`}
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder='Name'
              required
            />

            <input
              type='tel'
              className={`input-box fs-19 mt16 ${popins.className}`}
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChange}
              placeholder='Phone Number'
              required
            />

            <input
              type='email'
              className={`input-box fs-19 mt16 ${popins.className}`}
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder='Email'
              required
            />

            <input
              type='text'
              className={`input-box fs-19 mt16 ${popins.className}`}
              name="course"
              value={formData.course}
              onChange={handleChange}
              placeholder='Course Of Interest'
              required
            />

            <button type="submit" className={`submit-btn pdtb10 mt16 cursor fs-14 cw ${popins.className}`}>
              {loading ? (
          <div className="spinner-container">
            <div className="spinner"></div>
          </div>) : "Submit"}
            </button>
          </form>
        </div>
        </div>
      </section>

      <style jsx>{`
       /* Disabled state */
.btn:disabled {
  background-color: #B31942; /* Lighter background color when disabled */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
  opacity: 0.6; /* Reduce opacity when disabled */
}

/* Spinner container */
.spinner-container {
  display: flex; /* Center spinner */
  align-items: center; /* Vertically center spinner */
  justify-content: center;
}

/* Spinner styles */
.spinner {
  border: 8px solid rgba(0, 0, 0, 0);
  border-left-color: #ffffff; /* Spinner color */
  border-radius: 50%;
  width: 20px; /* Spinner size */
  height: 20px; /* Spinner size */
  animation: spin 1s linear infinite;
}

/* Spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
        .form-box {
          padding: 38px 20px;
          border-radius: 10px;
          box-shadow: 0px 4px 16px 8px #00000040;
          background-color: #FFFFFF;
        }

        .input-box {
          width: 100%;
          border-radius: 10px;
          padding: 10px 0px 10px 10px;
          border: 1px solid #000000;
        }

        .submit-btn {
          background-color: #FDBB39;
          border-radius: 8px;
          width: 100%;
          border: none;
        }
          @media only screen and (max-width: 650px){
            .form-box {
          padding: 10px 20px;
          border-radius: 10px;
          box-shadow: 0px 4px 16px 8px #00000040;
          width: auto !important;
        }
          }
      `}</style>
    </>
  );
}
